var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('div',[_c('gl-loading-icon',{attrs:{"size":"md"}})],1):_c('gl-table-lite',{attrs:{"items":_vm.report,"fields":_vm.$options.fields,"foot-clone":""},scopedSlots:_vm._u([{key:"cell(spentAt)",fn:function(ref){
var spentAt = ref.item.spentAt;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(spentAt)))])]}},{key:"foot(spentAt)",fn:function(){return [_vm._v(" ")]},proxy:true},{key:"cell(user)",fn:function(ref){
var user = ref.item.user;
return [_c('div',[_vm._v(_vm._s(user.name))])]}},{key:"foot(user)",fn:function(){return [_vm._v(" ")]},proxy:true},{key:"cell(timeSpent)",fn:function(ref){
var timeSpent = ref.item.timeSpent;
return [_c('div',[_vm._v(_vm._s(_vm.formatTimeSpent(timeSpent)))])]}},{key:"foot(timeSpent)",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.getTotalTimeSpent()))])]},proxy:true},{key:"cell(summary)",fn:function(ref){
var ref_item = ref.item;
var summary = ref_item.summary;
var note = ref_item.note;
return [_c('div',[_vm._v(_vm._s(_vm.getSummary(summary, note)))])]}},{key:"foot(note)",fn:function(){return [_vm._v(" ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }